<template>
  <section
    id="form"
    class="px-2 flex flex-col gap-y-3 divide-x-0 divide-y divide-solid divide-gray-200"
  >
    <div class="grid grid-cols-1 lg:grid-cols-4 gap-x-2">
      <!-- Patient -->
      <div class="flex flex-col gap-y-1">
        <label class="font-EffraR text-base text-gray-800">Client</label>
        <div class="relative">
          <multiselect
            :searchable="true"
            :internal-search="true"
            :preserve-search="true"
            :multiple="false"
            v-model="data.patientID"
            :options="patients"
            placeholder="Client"
            track-by="_id"
            :custom-label="fullNamePatient"
            class="doksingupselect"
          >
            <template v-slot:noResult>
              Il n'ya aucun résultat
            </template>
            <slot name="caret">X</slot>
          </multiselect>
        </div>
      </div>
      <!-- Reference -->
      <dok-input
        label="Reference"
        placeholder="Reference"
        size="lg"
        :d-model.sync="data.refCode"
      ></dok-input>
      <!-- Periode -->
      <dok-select label="Periode" size="lg" :d-model.sync="data.periode">
        <template v-slot:options>
          <option
            v-for="(item, index) in periodes"
            :key="index"
            :value="item.value"
            >{{ item.text }}</option
          >
        </template>
      </dok-select>
      <!-- Date Created -->
      <dok-input
        size="lg"
        d-type="date"
        label="Date de creation"
        :d-model.sync="data.issueingDate"
      ></dok-input>
    </div>

    <div class="flex flex-col gap-y-5">
      <h1 class="font-EffraM text-2xl text-grayDok-sidebar pt-2">
        Facture items
      </h1>

      <table
        class="min-w-max w-full table-auto border border-solid border-gray-400 rounded"
      >
        <thead>
          <tr
            class="bg-gray-100 rounded-t text-gray-600 uppercase text-sm leading-normal font-EffraR"
          >
            <th class="py-3 px-2">Designation</th>
            <th class="py-3 px-2">Prix UNIT.HT</th>
            <th class="py-3 px-2">QTE</th>
            <th class="py-3 px-2">MONTANT HT</th>
            <th class="py-3 px-2">TVA</th>
            <th class="py-3 px-2">OPTIONS</th>
          </tr>
        </thead>
        <tbody class="text-gray-600 text-sm font-light">
          <tr
            class="border-b border-gray-200 hover:bg-gray-100 relative"
            v-for="(fecture, index) in data.items"
            :key="index"
          >
            <td class="py-3 px-2">
              <div class="relative">
                <input
                  v-model="fecture.designation"
                  @input="onDesignation(index)"
                  placeholder="Designation"
                  type="text"
                  class="border-2 border-solid border-borderInput rounded px-1 w-full font-EffraR text-black placeholder-placeholderInput input-md"
                  style="height: 40px; font-size: 16px;"
                />
                <div
                  class="absolute inset-x-0 bg-white shadow border border-solid border-graywhite"
                  v-if="showServices === index"
                >
                  <div class="flex justify-end">
                    <button
                      class="border-0 bg-transparent focus:outline-none p-1"
                      @click="showServices = false"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                  <ul>
                    <li
                      class="py-1 px-1 font-EffraR text-base text text-black cursor-pointer"
                      v-for="(item, indexx) in searchServices"
                      :key="indexx"
                      @click="
                        setDesignation(index, {
                          id: item.id,
                          title: item.title
                        })
                      "
                    >
                      {{ item.title }}
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td class="py-3 px-2">
              <dok-input
                size="md"
                d-type="number"
                d-placeholder="0"
                :d-model.sync="fecture.priceUHT"
              ></dok-input>
            </td>
            <td class="py-3 px-2">
              <dok-input
                size="md"
                d-type="number"
                d-placeholder="0"
                :d-model.sync="fecture.quantity"
              ></dok-input>
            </td>
            <td class="py-3 px-2">
              <input
                :class="
                  `border-2 border-solid border-borderInput rounded px-1 w-full font-EffraR text-black placeholder-placeholderInput`
                "
                :value="fecture.quantity * fecture.priceUHT"
                style="height: 40px; font-size: 16px;"
                readonly
              />
            </td>
            <td class="py-3 px-2">
              <dok-input
                size="md"
                d-type="text"
                d-placeholder="0"
                :d-model.sync="fecture.TVA"
              ></dok-input>
            </td>
            <td class="py-3 px-2">
              <dok-button bg="rose" @click.native="deleteFacture(index)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18 6L6 18M6 6l12 12"
                  />
                </svg>
              </dok-button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              <dok-button
                custom-class="w-full"
                size="md"
                @click.native="addFactureColumn"
                >Ajouter une nouvelle ligne</dok-button
              >
            </td>
          </tr>
        </tfoot>
      </table>

      <div class="flex justify-end">
        <div class="flex flex-col gap-y-2">
          <div class="flex items-center gap-x-2">
            <span class="font-EffraR text-gray-600 text-lg w-100"
              >Total Ht:</span
            >
            <span class="font-EffraR text-black text-lg"
              >{{ data.sousTotal ? `${data.sousTotal} MAD` : "--" }}
            </span>
          </div>
          <div class="flex items-center gap-x-2">
            <span class="font-EffraR text-gray-600 text-lg w-100"
              >Total TVA:</span
            >
            <span class="font-EffraR text-black text-lg">{{
              data.totalTVA ? `${data.totalTVA} MAD` : "--"
            }}</span>
          </div>
          <div class="flex items-center gap-x-2">
            <span class="font-EffraR text-gray-600 text-lg w-100">TTC:</span>
            <span class="font-EffraR text-black text-lg">{{
              data.totalTTC ? `${data.totalTTC} MAD` : "--"
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <dok-button size="md" bg="trans" @click.native="onSave" data
      >Enregistrer</dok-button
    >
  </section>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
// Refactor Code
export default {
  data() {
    return {
      data: {
        items: [
          {
            designation: "",
            priceUHT: 0,
            quantity: 1,
            unit: "",
            TVA: 20
          }
        ],
        refCode: "FA-20111",
        issueingDate: moment().format("YYYY-MM-DD"),
        sousTotal: NaN,
        totalTVA: NaN,
        totalTTC: NaN
      },
      periodes: [
        {
          value: 0,
          text: "Facture normal"
        },
        {
          value: 1,
          text: "Un mois (1)"
        },
        {
          value: 3,
          text: "Trois mois (3)"
        },
        {
          value: 6,
          text: "Six mois (6)"
        },
        {
          value: 12,
          text: "Une année (1)"
        }
      ],
      showServices: false,
      searchServices: [],
      patients: []
    };
  },
  computed: {
    ...mapGetters({
      services: "service/services",
      totalFactures: "facture/totalFactures"
    })
  },
  watch: {
    "data.items": {
      deep: true,
      handler(newVal) {
        this.itemsCalculator();
      }
    }
  },
  async created() {
    await this.GET_LIST({
      onData: ({ ok, data }) => {
        if (ok) {
          this.patients = data;
        }
      }
    });
  },
  methods: {
    ...mapActions("client", ["GET_LIST"]),
    fullNamePatient({ firstName, lastName }) {
      return `${firstName} ${lastName}`;
    },
    onDesignation(index) {
      let value = event.target.value;

      if (value) {
        let results = _.filter(this.services, ["title", value]);
        if (results.length) {
          this.searchServices = results;
          this.showServices = index;
        }
      }
    },
    setDesignation(index, item) {
      this.data.items[index].designation = item.title;
      this.data.items[index].service = item.id;

      this.showServices = null;
    },
    addFactureColumn() {
      this.data.items.push({
        designation: "",
        priceUHT: 0,
        quantity: 1,
        unit: "",
        TVA: 20
      });
    },
    deleteFacture(index) {
      this.data.items.splice(index, 1);
    },
    itemsCalculator() {
      let totalPriceUHT = 0;
      let totalTVA = 0;
      this.data.items.forEach(item => {
        let itemPriceUHT = item.priceUHT * item.quantity;
        totalPriceUHT += itemPriceUHT;
        totalTVA += (itemPriceUHT * item.TVA) / 100;
      });
      this.data.sousTotal = totalPriceUHT;
      this.data.totalTVA = totalTVA;
      this.data.totalTTC = totalPriceUHT + totalTVA;
    },
    onSave() {
      console.log(this.data);
    }
  }
};
</script>

<style lang="scss">
.doksingupselect .multiselect__tag-icon:hover {
  background: #97b5ff !important;
  color: #2e6bff !important;
}

.doksingupselect .multiselect__tag {
  background: #e4e4e4 !important;
  color: #2e6bff !important;
}

.doksingupselect .multiselect__tags {
  min-height: 40px !important;
}

.has-error .multiselect__tags {
  border: 2px solid #ef4444 !important;
}

.doksingupselect .multiselect__tags {
  border: 2px solid #e4e4e4;
}

.doksingupselect .multiselect__select {
  height: 45px !important;
}

.doksingupselect .multiselect__placeholder {
  padding-top: 5px !important;
  color: #4f5968;
  @apply text-lg;
  /*#585858*/
}

.doksingupselect .multiselect__input,
.doksingupselect .multiselect__single {
  line-height: 30px;
  @apply font-EffraR;
}

.doksingupselect .multiselect__option--highlight {
  @apply bg-dokBlue-light;
}
.doksingupselect .multiselect__option--selected.multiselect__option--highlight {
  @apply bg-rosDok-light;
}
</style>
