<template>
  <section id="factures" class="py-10 flex flex-col gap-y-3">
    <header
      class="
        flex
        justify-between
        items-center
        gap-x-5
        w-full
        border-0 border-b border-solid border-grayborder
        pb-5
      "
    >
      <h1 class="flex-1 font-EffraM text-2xl">Factures</h1>
      <div class="flex items-center gap-x-2 w-auto">
        <dok-select size="md" :d-model.sync="search.attr">
          <template v-slot:options>
            <option
              :key="index"
              :value="item.value"
              v-for="(item, index) in attributes"
            >
              {{ item.text }}
            </option>
          </template>
          <template v-slot:rightIcon>
            <i class="fas fa-angle-down"></i>
          </template>
        </dok-select>
        <dok-input
          size="md"
          d-placeholder="Search ..."
          :d-model.sync="search.term"
        ></dok-input>
        <dok-button
          size="md"
          custom-class="whitespace-no-wrap"
          @click.native="modal.show = true"
          >Créer une nouvelle facture</dok-button
        >
      </div>
    </header>

    <section id="content">
      <div
        class="
          grid
          gap-x-3
          grid-cols-8
          px-2
          rounded-t
          divide-y-0 divide-x divide-solid divide-gray-400
          bg-gray-100
          text-gray-700
          border-0
          border-l
          border-r
          border-t
          border-b
          border-solid
          border-gray-400
        "
      >
        <div class="font-EffraM text-base py-3 px-2">Ref</div>
        <div class="font-EffraM text-base py-3 px-2">Client</div>
        <div class="font-EffraM text-base py-3 px-2">Date</div>
        <div class="font-EffraM text-base py-3 px-2">Prix HT</div>
        <div class="font-EffraM text-base py-3 px-2">TVA</div>
        <div class="font-EffraM text-base py-3 px-2">Prix TTC</div>
        <div class="font-EffraM text-base py-3 px-2">Recette Montant</div>
        <div class="font-EffraM text-base py-3 px-2">Actions</div>
      </div>

      <div
        v-for="(tr, index) in []"
        :key="index"
        class="
          grid
          gap-x-3
          grid-cols-8
          px-2
          divide-y-0 divide-x divide-solid divide-gray-400
          bg-white
          text-black
          border-0 border-r border-l border-b border-solid border-gray-400
        "
      >
        <div class="font-EffraR text-base py-3 px-2">{{ tr.refCode }}</div>
        <div class="font-EffraR text-base py-3 px-2">
          {{
            tr.client.reasonSocial ||
            tr.client.firstName + " " + tr.client.lastName
          }}
        </div>
        <div class="font-EffraR text-base py-3 px-2">
          {{ dateFormat(tr.issueingDate) }}
        </div>
        <div class="font-EffraR text-base py-3 px-2">
          {{
            tr.sousTotal
              ? tr.sousTotal.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "-"
          }}
        </div>
        <div class="font-EffraR text-base py-3 px-2">
          {{
            tr.totalTVA
              ? tr.totalTVA.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "-"
          }}
        </div>
        <div class="font-EffraR text-base py-3 px-2">
          {{
            tr.totalTTC
              ? tr.totalTTC.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "-"
          }}
        </div>
        <div class="font-EffraR text-base py-3 px-2">
          {{ tr.recipe.length ? tr.recipe[0].price : "-" }}
        </div>
        <div class="font-EffraR text-base py-3 px-2 flex items-center gap-x-2">
          <dok-button
            size="md"
            bg="blue"
            custom-class="w-full"
            @click="onEdit(tr, indextr)"
            >Edit</dok-button
          >
          <dok-button
            size="md"
            bg="rose"
            custom-class="w-full"
            @click="onDelete(tr._id)"
            >Delete</dok-button
          >
          <dok-button
            size="md"
            bg="trans"
            custom-class="w-full"
            @click="onViewfacture(tr)"
            >Viewer</dok-button
          >
        </div>
      </div>
    </section>

    <!-- Modal -->
    <modal
      :isActive="modal.show"
      :exitModal="
        () => {
          modal.show = false;
        }
      "
      :width="800"
      :hiddenHeader="true"
    >
      <header
        class="
          py-5
          px-2
          flex
          items-center
          justify-between
          border-0 border-b border-solid border-gray-200
          mb-10
        "
      >
        <h1 class="font-EffraR text-2xl text-black">
          Créer une nouvelle facture
        </h1>
        <button
          class="bg-transparent border-0 cursor-pointer"
          @click="modal.show = false"
        >
          <img src="/svg/x.svg" />
        </button>
      </header>
      <add-facture :facture="facture"></add-facture>
    </modal>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import AddFacture from "./Add";
import moment from "moment";
import modal from "@/views/global-components/modal";

export default {
  data() {
    return {
      modal: {
        show: false,
      },
      attributes: [
        {
          text: "Tous",
          value: "",
        },
        {
          text: "Client",
          value: "client",
        },
        {
          text: "Reference",
          value: "refCode",
        },
        {
          text: "Prix HT",
          value: "sousTotal",
        },
        {
          text: "Prix TTC",
          value: "totalTTC",
        },
        {
          text: "TVA",
          value: "totalTVA",
        },
      ],
      selected: [],
      selectedIndex: -1,
      itemsPerPage: 50,
      isMounted: false,
      facture: null,
      search: {
        page: 1,
        term: "",
        attr: "",
      },
    };
  },
  components: {
    modal,
    AddFacture,
  },
  computed: {
    ...mapGetters({
      factures: "facture/factures",
      totalItems: "facture/totalItems",
    }),
  },
  watch: {
    "search.term": function (newVal, oldVal) {
      this.search.page = 1;
      this.onSearch();
    },
    "search.page": function (newVal, oldVal) {
      this.onSearch();
    },
  },
  methods: {
    onSearch() {
      this.$store.dispatch("facture/GET_LIST", {
        search: this.search,
      });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    onClose() {
      this.facture = {};
    },
    onDelete(factureId) {
      this.toRemove = factureId;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: "Voulez-vous vraiment supprimer cet article",
        accept: this.confirmDelete,
      });
    },
    confirmDelete() {
      this.$store.dispatch("facture/DELETE", this.toRemove).then((res) => {
        this.$vs.notify({
          time: 4000,
          title: "Supprimé",
          text: "Supprimé avec succès",
          color: "success",
          iconPack: "feather",

          icon: "icon-check",
        });
      });
    },
    onEdit(facture, index) {
      this.facture = facture;
      this.selectedIndex = index;
    },
    onViewfacture(facture) {
      window.open(
        `https://api.dok.ma/api/v1/facture/${
          facture._id
        }/download?token=${this.$auth.getToken()}`,
        "_blank"
      );
    },
  },
  created() {
    this.$store.dispatch("facture/GET_LIST");
    this.onSearch();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
